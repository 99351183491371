import { isEmpty } from "lodash-es";
import { useReport } from "./useReport";
import type { VoucherDetail } from "~/types/VoucherTransaction";

export default function useShareVoucher() {
  const shareTitle =
    "Check out your gift!! enjoy special experience (Hungry Hub gift card)";

  async function getShareUrl(voucher: VoucherDetail) {
    try {
      const configStore = (await import("~/stores/config")).default;
      const useConfigStore = configStore();
      const baseUrl = useConfigStore.backendConfig.webV2Host;
      if (isEmpty(voucher)) {
        useReport({
          level: "error",
          message: "Failed share voucher, missing voucher object",
        });
        return "";
      }

      return `${baseUrl}/vouchers/${voucher.attributes.encryptedId}?token=${voucher.attributes.token}`;
    } catch (error) {
      useReport({
        level: "error",
        message: "Failed share voucher",
      });
      return "";
    }
  }

  async function shareVoucher(voucher: VoucherDetail) {
    if (isEmpty(voucher)) {
      useReport({
        level: "error",
        message: "Failed share voucher, missing voucher object",
      });
      return;
    }

    const { useShare } = await import("@vueuse/core");
    const { isSupported, share } = useShare();
    const voucherShareLandingPage = await getShareUrl(voucher);
    if (isSupported) {
      const shareMessage = `${shareTitle} ${voucherShareLandingPage}`;
      share({
        title: shareTitle,
        text: shareMessage,
        url: voucherShareLandingPage,
      });
      return;
    }
    window.open(voucherShareLandingPage, "_blank");
  }

  return { getShareUrl, shareTitle, shareVoucher };
}
